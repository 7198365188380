import * as THREE from 'three'

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
// scene.background = null;
scene.background = new THREE.Color( 0xffffff );

const geometry = new THREE.BoxGeometry( 1.25, 1.25, 1.25 );
const material = new THREE.MeshBasicMaterial( { color: 0xffffff } );
const cube = new THREE.Mesh( geometry, material );
scene.add( cube );

// Lights

const pointLight = new THREE.PointLight(0xffffff, 0.1)
pointLight.position.x = 2
pointLight.position.y = 3
pointLight.position.z = 4
scene.add(pointLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

function updateDimensionCameraRenderer() {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}

window.addEventListener('resize', updateDimensionCameraRenderer)

if (screen.orientation) { // Property doesn't exist on screen in IE11   
    screen.orientation.addEventListener("change", updateDimensionCameraRenderer);
}

const camera = new THREE.PerspectiveCamera(20, sizes.width / sizes.height, 0.1, 1000)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 2
scene.add(camera)

// edges geometry
var edges_geometry = new THREE.EdgesGeometry( cube.geometry ); // or WireframeGeometry
var edges_material = new THREE.LineBasicMaterial( { color: 0x000000, linewidth: 1 } );
var edges = new THREE.LineSegments( edges_geometry, edges_material );
cube.add( edges ); // add wireframe as a child of the parent mesh

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor( 0xffffff, 0 )

// cube.rotation.x += Math.PI / 4;
cube.rotation.x += toRadians(47);
// cube.rotation.y += Math.PI / 5.1428;
cube.rotation.y += toRadians(32);
// cube.rotation.z += Math.PI / 4;
cube.rotation.z -= toRadians(18);

renderer.render( scene, camera );


var animate_x_positive = true;
var animate_y_positive = true;
var animate_z_positive = false;


function animate() {
	requestAnimationFrame( animate );

    if (animate_x_positive) {
        if ( toDegrees(cube.rotation.x) <= 50 ) {
            cube.rotation.x += toRadians(0.01);
        } else {
            animate_x_positive = false;
        }
    } else {
        if ( toDegrees(cube.rotation.x) >= 44 ) {
            cube.rotation.x -= toRadians(0.01);
        } else {
            animate_x_positive = true;
        }
    }

    if (animate_y_positive) {
        if ( toDegrees(cube.rotation.y) <= 33.5 ) {
            cube.rotation.y += toRadians(0.005);
        } else {
            animate_y_positive = false;
        }
    } else {
        if ( toDegrees(cube.rotation.y) >= 30.5 ) {
            cube.rotation.y -= toRadians(0.005);
        } else {
            animate_y_positive = true;
        }
    }

    if (!animate_z_positive) {
        if ( toDegrees(cube.rotation.z) >= 15 ) {
            cube.rotation.z -= toRadians(0.01);
        } else {
            animate_z_positive = false;
        }
    } else {
        if ( toDegrees(cube.rotation.z) <= 32 ) {
            cube.rotation.z += toRadians(0.01);
        } else {
            animate_z_positive = true;
        }
    }
    
	renderer.render( scene, camera );
}
animate();

function toRadians(angle) {
	return angle * (Math.PI / 180);
}

function toDegrees(angle) {
    return angle * (180 / Math.PI);
}

let direction = "";
let oldx = 0;
let oldy = 0;
let initialRotationValue = 0.000005;

let mousemovemethod = (e) => {
    
    if (e.pageX > oldx && e.pageY == oldy) {
        direction="East";
    } else if (e.pageX == oldx && e.pageY > oldy) {
        direction="South";
    } else if (e.pageX == oldx && e.pageY < oldy) {
        direction="North";
    } else if (e.pageX < oldx && e.pageY == oldy) {
        direction="West";
    }
        
    oldx = e.pageX;
    oldy = e.pageY;

    return direction;
}

function onDocumentMouseMove( event ) {
    let repetation = 5;

    direction  = mousemovemethod(event)

    if ( direction == "East") {
        for (let i = 0; i <= repetation*24; i++) {
            // cube.rotation.y += (initialRotationValue - initialRotationValue*i/100);
            cube.rotation.y += initialRotationValue;
            //cube.rotation.z += (initialRotationValue - initialRotationValue*i/100);
        }
    } else if ( direction == "West" ) {
        for (let i = 0; i <= repetation*24; i++) {
            // cube.rotation.y -= (initialRotationValue - initialRotationValue*i/100);
            cube.rotation.y -= initialRotationValue;
            //cube.rotation.z -= (initialRotationValue - initialRotationValue*i/100);
        }
        
    } else if ( direction == "North" ) {
        for (let i = 0; i <= repetation*12; i++) {
            //cube.rotation.x -= (initialRotationValue - initialRotationValue*i/100);
            //cube.rotation.z -= (initialRotationValue - initialRotationValue*i/100);
            cube.rotation.x -= initialRotationValue;
            cube.rotation.z -= initialRotationValue;
        }
        
    } else if ( direction == "South" ) {
        for (let i = 0; i <= repetation*12; i++) {
            //cube.rotation.x += (initialRotationValue - initialRotationValue*i/100);
            //cube.rotation.z += (initialRotationValue - initialRotationValue*i/100);
            cube.rotation.x += initialRotationValue;
            cube.rotation.z += initialRotationValue;
        }
    }
}

document.addEventListener( 'mousemove', onDocumentMouseMove, false );